// Components (.vue)
import BasicHeader from '@/Components/Global/BasicHeader/template.vue'
import MenuBar     from '@/Components/Global/MenuBar/template.vue'
import SidePanel   from '@/Components/Global/SidePanel/template.vue'

// Components (Refs)
import { MenuBarRef }   from '@/Components/Global/MenuBar/component'
import { SidePanelRef } from '@/Components/Global/SidePanel/component'

// Constants
import { VueRouter } from '@/Constants/VueRouter'

// Dependencies
import VueMixins  from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'
import { Breakpoints } from '@/Constants/Breakpoints'
import { AppModules } from '@/Constants/AppModules'

// Component Extend
const ViewStatistics = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: VueRouter.Views.Modules.STATISTICS_CPANEL.NAME,

	components: {
		BasicHeader,
		MenuBar,
		SidePanel
	},

	data: function() {
		return {
			states: {
				dateEnd: '',
				dateStart: ''
			}
		}
	},

	mounted: function() {
		this._sidePanel.setStates<SidePanelRef['states']>({ showStoragesList: true })
	},

	computed: {
		_menuBar: function(): MenuBarRef {
			return this.$refs.menuBar as MenuBarRef
		},

		_sidePanel: function(): SidePanelRef {
			return this.$refs.sidePanel as SidePanelRef
		}
	},

	methods: {
		_onResponsiveBreakpoint: function(breakpoint: number) {
			// Cambios que afectan el aspecto visual de los Componentes (según Breakpoint).
			this._menuBar.setItems(AppModules.Components.MenuBar.StatisticsItemOptions(breakpoint <= Breakpoints.Large))
			this._menuBar.setStates<MenuBarRef['states']>({ isMobile: breakpoint <= Breakpoints.Large })
		},

		onMBItemClick: function(key: number) {

		}
	}
})

// Exports
export default ViewStatistics
export type ViewStatisticsRef = InstanceType<typeof ViewStatistics>